import http from '../http/api'
export default () => {
  return {
    username: (rule, value, callback) => {
      let rules = /^[a-zA-Z0-9]{4,16}$/
      if (!rules.test(value)) return callback(new Error('用户名只能是4到16位的数字、字母'))
      callback()
    },
    password: (rule, value, callback) => {
      let rules = /^.{6,20}$/
      if (!rules.test(value)) return callback(new Error('密码为6-20位任意字符'))
      callback()
    },
    fullName: (rule, value, callback) => {
      let rules = /^([\u4e00-\u9fa5]{2,30}|[a-zA-Z\s]{2,30})$/
      if (!rules.test(value)) return callback(new Error('姓名只能包含汉字或字母且长度2-30位'));
      callback()
    },
    mobile: (rule, value, callback) => {
      let rules = /^1[3|4|5|6|7|8|9][0-9]\d{8}(,1[3|4|5|6|7|8|9][0-9]\d{8})*$/
      if (!rules.test(value)) return callback(new Error('请输入正确的手机号'));
      callback()
    },
    code: (rule, value, callback) => {

      let rules = /^[a-zA-Z0-9]{6}$/
      if (!rules.test(value)) return callback(new Error('验证码长度只能为6位'))
      callback()
    },
    VerificationMobile: async (rule, value, callback) => {
      let rules = /^1[3|4|5|6|7|8|9][0-9]\d{8}(,1[3|4|5|6|7|8|9][0-9]\d{8})*$/
      if (!rules.test(value)) return callback(new Error('请输入正确的手机号'));
      const {
        data: data
      } = await http.isRegistered({
        mobile: value,
      });
      if (data.code == 200) return callback(new Error('手机号已注册'))
      callback()
    },
    Pcode: (rule, value, callback) => {

      let rules = /^[a-zA-Z0-9]{4}$/
      if (!rules.test(value)) return callback(new Error('验证码长度只能为4位'))
      callback()
    },
  }
}