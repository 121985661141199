<template>
  <div class="my clearfix">
    <div v-if="changeMy">
      <div class="myTop">
        <ul>
          <li class="tableName">
            <i class="el-icon-caret-right"></i>
            账号基本信息
            <el-button class="fr" @click="changeMyDialog = true;changeMy=false">修改</el-button>
          </li>
          <li>
            <div>
              姓
              <span class="fr">名:</span>
            </div>
            {{userInfo.realName}}
          </li>
          <li>
            <div>手机号:</div>
            {{userInfo.mobile}}
          </li>
          <li>
            <div>
              头
              <span class="fr">像:</span>
            </div>
            <div class="userPicture">
              <img
                :src="'https://api.joyousoa.com/authority-center/open/avatar/'+userInfo.username"
                alt
                @error="handleError"
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="myBot">
        <p class="tableName">
          <i class="el-icon-caret-right"></i>
          密码设置
          <el-button class="fr" @click="changePasDialog = true;changeMy=false">修改</el-button>
        </p>
        <div class="pssChange">
          <div>
            密
            <span class="fr">码:</span>
          </div>
          <span class="pasText">**********</span>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="changeMyDialog" :before-close="handleClose" class="changeMy">
      <p class="tableName" slot="title">
        <i class="el-icon-caret-right"></i>
        账号基本信息
      </p>
      <el-form
        label-width="72px"
        ref="userInfo"
        :model="userInfo"
        key="userInfo"
        :rules="userInfoRules"
      >
        <el-form-item prop="realName">
          <div slot="label">
            <span class="fl">姓</span>
            <span class="fr">名:</span>
          </div>
          <el-input v-model="userInfo.realName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="userInfo.mobile"></el-input>
        </el-form-item>
        <el-form-item class="userP">
          <div slot="label">
            <span class="fl">头</span>
            <span class="fr">像:</span>
          </div>
          <el-upload
            class="upload-demo"
            ref="upload"
            :headers="uploadHeaders"
            :file-list="fileList"
            :auto-upload="false"
            list-type="picture-card"
            :on-change="pictureChange"
            :on-success="success"
            :on-exceed="exceed"
            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
            action="https://api.joyousoa.com/authority-center/common/account/updateAvatar"
          >
            <i slot="default" class="el-icon-refresh"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitUpload">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="changePasDialog"
      :before-close="handleClose"
      class="changePas"
    >
      <p class="tableName" slot="title">
        <i class="el-icon-caret-right"></i>
        密码设置
      </p>
      <el-form
        class="changePasForm"
        key="changePasForm"
        :model="changePasForm"
        ref="changePasForm"
        :rules="changePasFormRules"
      >
        <el-form-item prop="oldPas">
          <el-input placeholder="请输入原密码" v-model="changePasForm.oldPas"></el-input>
        </el-form-item>
        <el-form-item prop="newPas">
          <el-input placeholder="请输入新密码" show-password v-model="changePasForm.newPas"></el-input>
        </el-form-item>
        <el-form-item prop="newPasAg">
          <el-input placeholder="请再次输入新密码" show-password v-model="changePasForm.newPasAg"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changePas">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import http from "@/http/api";
import defaultRules from "@/utils/formRules.js";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      changeMy: true,
      changeMyDialog: false,
      changePasDialog: false,
      changePasForm: {
        oldPas: "",
        newPas: "",
        newPasAg: "",
      },
      changeMyForm: {},
      userPUrl: "",
      options: {
        classOptions: [],
        gradeOptions: [],
      },
      dialogImageUrl: "",
      fileList: [],
      file_list_temp: [],
      disabled: false,
      userInfo: {
        id: "",
        mobile: "",
        orgId: "",
        orgName: "",
        orgType: "",
        realName: "",
        username: "",
      },
      // 上传头像请求头设置
      uploadHeaders: {
        Authorization:
          "Bearer" + " " + window.localStorage.getItem("Authorization"),
      },
      img: null,
      changePasFormRules: {
        oldPas: [{ validator: defaultRules().password, trigger: "blur" }],
        newPas: [{ validator: defaultRules().password, trigger: "blur" }],
        newPasAg: [{ validator: defaultRules().password, trigger: "blur" }],
      },
      userInfoRules: {
        realName: [{ validator: defaultRules().fullName, trigger: "blur" }],
        mobile: [{ validator: defaultRules().mobile, trigger: "blur" }],
      },
    };
  },
  created() {
    // 获取用户个人信息
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    // 获取用户个人信息
    async getUserInfo() {
      const data = await http.getUserInfo();
      if (data.status == 200) {
        this.userInfo = data.data;
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },

    // 默认头像
    handleError(e) {
      e.target.src = require("@/assets/user1.jpg");
    },
    handleClose(done, massage) {
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }

      this.fileList = [];
      this.changeMyDialog = false;
      this.changeMy = true;
      this.changePasDialog = false;
    },

    // 超出限制
    exceed(val) {
      this.$message.error({
        message: "更换头像请先删除前头像",
        type: "error",
        duration: 3000,
      });
    },
    //
    success(res) {
      if (res.code !== 200) {
        this.$message.error({
          message: res.msg,
          type: "error",
          duration: 3000,
        });
      }
      location.reload();
    },
    // 文件类型限制
    beforeAvatarUpload(file) {},
    // 自定义个人信息上传
    uploadFile(params) {
      let formData = new FormData();

      if (this.fileList.length) {
        formData.append("file", this.fileList[0]);
      }

      this.$refs.upload.submit();

      http
        .changeMyInfo(
          { realName: this.userInfo.realName, mobile: this.userInfo.mobile },
          formData
        )
        .then((res) => {
          this.changeMyDialog = false;
        })
        .catch(() => {
          this.changeMyDialog = false;
        });

      // :action="'https://api.joyousoa.com/authority-center/common/account/updateInfo?realName='+userInfo.realName+'&mobile='+userInfo.mobile"
    },
    changePas() {
      this.$refs["changePasForm"].validate(async (valid) => {
        if (valid) {
          if (
            !this.changePasForm.oldPas ||
            !this.changePasForm.newPas ||
            !this.changePasForm.newPasAg
          )
            return this.$message.error({
              message: "请输入完整信息后再提交!",
              type: "error",
              duration: 3000,
            });
          if (this.changePasForm.newPas !== this.changePasForm.newPasAg)
            return this.$message.error({
              message: "您两次输入的新密码不一致,请确认后再提交!",
              type: "error",
              duration: 3000,
            });
          if (this.changePasForm.oldPas == this.changePasForm.newPas)
            return this.$message.error({
              message: "您的新旧密码一致,请确认后再提交!",
              type: "error",
              duration: 3000,
            });

          const { data: data } = await http.changePas({
            oldPassword: this.changePasForm.oldPas,
            newPassword: this.changePasForm.newPas,
          });
          if (data.code !== 200) {
            this.$message.error({
              message: data.msg,
              type: "error",
              duration: 3000,
            });
          } else {
            this.$message.error({
              message: "修改密码成功,请记住您的密码!",
              type: "error",
              duration: 3000,
            });
            window.localStorage.removeItem("Authorization");
            this.$router.push("login");
          }
          this.changePasDialog = false;
          this.changeMy = true;
          this.changePasForm.oldPas = "";
          this.changePasForm.newPas = "";
          this.changePasForm.newPasAg = "";
        } else {
          this.$message.error({
            message: "请输入完成信息",
            type: "error",
            duration: 3000,
          });
          return false;
        }
      });
    },
    submitUpload() {
      this.$refs["userInfo"].validate((valid) => {
        if (valid) {
          this.uploadFile();
          this.changeMyDialog = false;
          this.changeMy = true;
        } else {
          this.$message.error({
            message: "请输入完成信息",
            type: "error",
            duration: 3000,
          });
          return false;
        }
      });
    },
    // handleRemove(file) {
    //   this.$refs["upload"].clearFiles();
    //   this.fileList = [];
    // },
    pictureChange(file, fileList) {
      this.$refs["upload"].clearFiles();
      this.fileList = [];
      this.fileList.push(file);
      // this.upPicture();
    },
    upPicture() {},
  },
};
</script>
<style lang='scss' scoped>
::v-deep.my {
  height: calc(100% - 80px);
  .myTop {
    margin: 70px auto;
    width: 629px;
    height: 392px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(131, 143, 153, 0.15);
    border-radius: 16px;
    padding: 38px 44px 0 38px;
    text-align: left;
    margin-bottom: 0;
    box-sizing: border-box;
    li {
      display: flex;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6d7278;
      margin-bottom: 38px;
      div {
        width: 59px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 25px;
        margin-right: 18px;
        margin-left: 16px;
      }
      .userPicture {
        width: 134px;
        height: 128px;
        border-radius: 4px;
        border: 1px solid #c1c7cc;
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          background: url(../../assets/logo.png) left no-repeat;
          background-size: cover;
        }
        img {
          width: 100%;
        }
      }
    }
    .tableName {
      display: block;
      height: 44px;
      line-height: 44px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #eb6123;
      margin-bottom: 26px;
      i {
        width: 20px;
        height: 20px;
        transform: scale3d(1.8, 1.2, 1);
        margin: 0 10px;
        text-shadow: 2px 0 3px #eb6123;
      }
      .el-button {
        height: 44px;
        line-height: 18px;
        width: 88px;
        border-radius: 28px;
        border: 1px solid #eb6123;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #eb6123;
      }
    }
  }
  .myBot {
    margin: 25px auto;
    width: 629px;
    height: 182px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(131, 143, 153, 0.15);
    border-radius: 16px;
    padding: 38px 40px;
    box-sizing: border-box;
    .tableName {
      display: block;
      height: 44px;
      line-height: 44px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #eb6123;
      text-align: left;
      margin-bottom: 40px;
      i {
        width: 20px;
        height: 20px;
        transform: scale3d(1.8, 1.2, 1);
        margin: 0 10px;
        text-shadow: 2px 0 3px #eb6123;
      }
      .el-button {
        height: 44px;
        line-height: 18px;
        width: 88px;
        border-radius: 28px;
        border: 1px solid #eb6123;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #eb6123;
      }
    }
    .pssChange {
      text-align: left;
      display: flex;
      div {
        width: 59px;
        margin-right: 16px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        margin-left: 16px;
      }
      .pasText {
        height: 36px;
        line-height: 36px;
      }
    }
  }
  .el-dialog {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    .el-dialog__header {
      height: 62px;
      line-height: 62px;
      padding: 0;
      border-bottom: 1px solid #eb6123;
      .tableName {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #eb6123;
        text-align: left;
        i {
          width: 20px;
          height: 20px;
          transform: scale3d(1.8, 1.2, 1);
          margin: 0 10px;
          text-shadow: 2px 0 3px #eb6123;
        }
      }
      .el-dialog__close {
        font-size: 22px;
        color: #eb6123;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      padding: 22px;
      box-sizing: border-box;
      height: 420px;
      .el-upload--picture-card {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }
    }
    .el-dialog__footer {
      .dialog-footer {
        display: block;
        width: 352px;
        height: 56px;
        margin: 0 auto;
        .el-button {
          width: 352px;
          height: 56px;
          font-weight: 700;
          border-radius: 28px;
          border: 1px solid #eb6123;
          background-color: #fff;
          color: #eb6123;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
      }
    }
  }
  .changeMy {
    width: 630px;
    height: 624px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(131, 143, 153, 0.15);
    border-radius: 16px;
    position: absolute;
    left: 50%;
    margin-left: -315px;
    top: 200px;
    padding: 34px;
    box-sizing: border-box;

    .el-dialog {
      text-align: left;
      .el-form {
        padding: 0 5px;
        .el-form-item {
          font-size: 18px;
          height: 56px;
          line-height: 56px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6d7278;
          label {
            height: 56px;
            line-height: 56px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.85);
          }
          .el-input {
            width: 352px;
            height: 56px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #c1c7cc;
            input {
              width: 100%;
              height: 100%;
            }
          }
          .el-form-item__content {
            height: 56px;
            line-height: 56px;
            padding-left: 10px;
            .el-form-item__error {
              padding-left: 10px;
            }
          }
        }
        .userP {
          height: 128px;
        }
      }
    }
  }
  .changePas {
    width: 629px;
    height: 624px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(131, 143, 153, 0.15);
    border-radius: 16px;
    position: fixed;
    left: 50%;
    margin-left: -315px;
    top: 200px;
    padding: 34px;
    box-sizing: border-box;
    .el-dialog {
      .el-form {
        padding: 20px 80px;
        .el-form-item {
          margin-bottom: 38px;
          .el-form-item__content {
            .el-form-item__error {
              padding-left: 5px;
            }
          }
          .el-input {
            width: 352px;
            height: 56px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #c1c7cc;
            input {
              width: 100%;
              height: 100%;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #6d7278;
            }
          }
        }
      }
    }
  }
}
</style>